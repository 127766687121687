.webFilterBar{
    position: fixed;
    width: 100%;
    /* top:100px; */
    /* font-family: var(--fontText);
    font-size: 15px;  */
    background-color: rgba(223, 242, 255, 0.641);
    /* margin-top: 75px; */
    height: fit-content;
    /* color: var(--lgt2); */
    /* transition: all 1s; */

}

/* card filter style */
.search-count-text {
    font-family: var(--fontText);
    color: var(--colorText);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
  }
  .card-filter {
    position: absolute;
    left: -150px;
    background-color: rgb(223, 242, 255);
    border-radius: 10px;
    padding: 15px;
    width: 180px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card-filter-item {
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
  }
  .card-filter-item:hover {
    background:linear-gradient(#fff,rgb(235, 247, 255));
    color: var(--colorTitle);
    border-radius: 15px;
  }
  /* end card filter */

  .title-filter{
    font-family: var(--fontTitle);
    color: var(--colorTitle);
  }