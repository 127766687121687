/* .product-gallary-card {
    height: 470px !important;
    width: 370px;
    border-radius: 29px;
    background-color: #ffffff;
  } */

.ProductGallery {
  /* width: 400px; */
  display: flex;
}

.ProductGallery .container1 {
  flex: 3;
}

.ProductGallery .container2 {
  flex: 1;
}

.ProductGallery .image1 {
  width: 100%;
  /* border: 1px solid black; */
  /* padding: 0 5px 5px 0; */
}

/* .ProductGallery .image2 {
  width: 100%;
} */

.ProductGallery .image2 {
  width: 100%;
  scale: 1;
  transition: scale 0.6s ease;
  z-index: 0;
}

.ProductGallery .image2:hover {

 scale: 1.1;
 z-index: 1;
}
