
.rate-name {
    color: #555550;
    font-family: "Comfortaa";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  }

  .input-form-area {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  .product-cart-add {
    cursor: pointer;
    text-align: center;
    height: 45px;
    border-radius: 9px;
    border: none;
    background-color: #272727;
    color: #ffffff;
    font-family: "Almarai";
    font-size: 14px;
    font-weight: normal;
  }
  .product-cart-add:hover {
    color: #bdbaba !important;
  }