/* products card style */
.card-store2 {
  font-family: var(--fontText);
  min-width: calc(100% / 5);
  width: 220px;

  .MainCard2 {
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.5);
    overflow: hidden;
    padding: 7px;
  }


  .cardImage {
    /* filter: drop-shadow(10px 8px 6px rgba(207, 207, 207, 0.7)); */
    overflow: hidden;
    color: #e6e6e6;
  }

  .card-title {
    color: var(--colorTitle);
    font-size: 16px;
    letter-spacing: 0;
    /* line-height: 20px; */
    text-align: left;
    /* height: 83px; */
    height: 2.25em;
    overflow: hidden;
    margin-block: 7px;
  }

  .card-oldPrice {
    color: var(--colorText);
    text-decoration: line-through;
    font-size: 12px;
    font-weight: bolder;
  }

  .card-price {
    color: var(--colorTitle);
    font-size: 28px;
    font-weight: bolder;
  }

  .card-discount-price {
    color: var(--colorText);
    font-size: 18px;
  }

  .card-discount {
    font-size: 14px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    
    color: red;
  }

  .card-rate {
    color: var(--gold);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    height: fit-content;
    /* line-height: 19px; */
  }

  /* .card-buttom {
    background-color: var(--colorText);
    border: none;
    border-radius: 40px;
    color: #fff;
    padding: 10px;
    margin-top: -10px;
  }

  .card-buttom:hover {
    background-color: var(--colorTitle);
    border: none;
    border-radius: 40px;
    padding: 10px;
  } */

}