
.col-img {
    background-image: url(../../assets/images/feature1.jpg);
    background-position: center;
    background-size: cover;
    min-height: 500px;
}

.bgdFeature{
    background-image: url(../../assets/images/background1.jpg);
    background-position: center; 
    background-size: cover;
}

.iconbox {
    width: auto;
    height: 100px;
   
    /* background-color: var(--primary); */
    color: #fff;
    font-size: 32px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
}
#features{
    --bs-gutter-x: 0rem;
    border-top:3px solid var(--primary);
    border-bottom:3px solid var(--primary);
    
}

#features p {
    color:var(--colorText);
    font-family:var(--fontText) ;
    font-size: 18px;
    line-height: 1.3em;
}


#features .featureTitle{
    position: relative;
    font-family:var(--fontTitle) ;
    color:var(--colorTitle);
}

#features .featureTitle::before {
    content: '';
    display: block;
    background-image: url('../../assets/feature/44.png');
    position: absolute;
   
    left: 0;
    transform: translate(-55%, -30%);
    bottom: 0.6em;
    width: 40px;
    height: 40px;
    background-size: cover;
    opacity: 0.9;

}