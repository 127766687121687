.ratingStars {
  margin-inline: auto;
}

.ratingStars label {
  /* display: inline-block; */
  color: #ccc;
  font-size: 40px;
}

.ratingStars .checked {
  color: var(--gold);
}

/* .ratingStars span label {
  font-size: 40px;
} */

/* .ratingStars span {
  font-size: 30px;
}

.ratingStars input {
  display: none;
} */