.productSlider2 {

    .title-section {
        font-weight: 700;
        text-transform: uppercase;
        color: var(--colorTitle);
    }

    .sliderBtn {
        height: fit-content;
        font-size: 14px;
        padding: 3px 7px;
        color: var(--colorTitle);
        border: 1px solid var(--colorTitle);
        border-radius: 3px;
        text-wrap: nowrap;
    }

    .pSlider {
        /* display: flex;
        flex-direction: row; */
        /* padding-block: 5px; */
    }

    .slick-track {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-inline: 0px;

    }
    
    .sliderBody {
        background-color: transparent;
    }
    

    .slick-track {
        min-width: 100svw;
    }
}

.card-store3 {
    font-family: var(--fontText);
    /* min-width: calc(100% / 5); */
    max-width: 220px;
    padding-inline: 5px;
    /* max-width: 220px; */

    .MainCard3 {
        width: fit-content;
        /* height: fit-content; */
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.5);
        overflow: hidden;
        padding: 7px;
    }


    .cardImage {
        overflow: hidden;
        color: #e6e6e6;
    }

    .card-title {
        color: var(--colorTitle);
        font-size: 16px;
        letter-spacing: 0;
        /* line-height: 20px; */
        text-align: left;
        /* height: 83px; */
        height: 2.25em;
        overflow: hidden;
        margin-block: 7px;
    }

    .card-oldPrice {
        color: var(--colorText);
        text-decoration: line-through;
        text-align: start;
        font-size: 12px;
        font-weight: bolder;
        /* padding-top: 10px; */
    }
    .card-price {
        color: var(--colorTitle);
        font-size: 24px;
        font-weight: bolder;
        /* padding-top: 10px; */
    }

    .card-discount-price {
        color: var(--colorText);
        font-size: 18px;
    }

    .card-discount {
        font-size: 10px;
        width: 100%;
        border-bottom: 1px solid lightgray;

        color: red;
    }

    .card-rate {
        color: var(--gold);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        height: fit-content;
        /* line-height: 19px; */
    }

}