:is(.login, .signup) input::placeholder {
  color: white;
}

.login .showPassword, .emailNothification {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

 h2 {
  color: white;
}

button.btn-primary:hover {
  color: #25485c;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}




button.btn-primary {
  /* margin-top: 30px; */
  background-color: #25485c;
  color: white;
  border: none;


}

.error-message {
  font-style: italic;
  color: red;
  font-size: 12px;
}

.signup-page .form-control::placeholder {
 color: #ffffff2b;
  /* background-color:#35536a94 */
}