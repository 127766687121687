.transactionBill {
    width: 100%;
    background-color: lightblue;
    font-size: 14px;

    .tableHeaderCell {
        font-weight: bolder;
        text-align: left;
        padding-inline: 3px;
    }

    /* .tableRow {} */

    .tableCell {
        text-align: left;
        color: darkblue;
        min-height: 1.5em;
        padding-inline: 3px;

    }

    .input {
        width: 100%;
        height: 100%;
        padding-inline: 10px;
        border: none;
    }

    .input:invalid {
        color: red;
    }

    .productKey {
        cursor: pointer;
    }

    .productContainer {
        display: none;
        width: 600px;
        /* height: 120px; */
        position: fixed;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        /* --product-image-width: 120px; */
        /* bottom: calc(100% + 120px); */
        /* position: relative;
        top: 1px;
        left: calc(100% + 13px); */
        background-color: white;
        border: 2px solid lightgray;
        border-radius: 10px;
        overflow: hidden;
        /* color: black; */
    }

    .productImageContainer {
        /* width: var(--product-image-width); */
        background-color: #d7ecff;
        border-right: 2px solid lightgray;
    }

    .productCInfo {
        font-size: 1em;
        color: black;
        background-color: aliceblue;
    }

    .productImage {
        width: 100%;
        /* aspect-ratio: 1/1; */
    }

    .productKey:hover + .productContainer {
        display: block;
    }

    .selectedColor {
        display: inline-block;
        height: 1em;
        width: 2em;
        border-radius: 3px;
    }
}