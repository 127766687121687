/* products card style */
.card-store {
  font-family: var(--fontText);
}

.card-title {
  color: var(--colorTitle);

  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  /* height: 83px; */
  height: 4.2em;
  overflow: hidden;
}

.card-price {
  color: var(--colorTitle);

  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.card-discount-price {
  color: var(--colorText);

}

.card-price {}

.card-currency {
  color: #555550;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.card-rate {
  color: var(--gold);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  height: fit-content;
  /* line-height: 19px; */
}

.card-store {
  /* border-radius: 30px;
    border: 2px solid #939595;
    background-color: #d9e6f1; */

}

/* @media (max-width: 576px){
  .card-store{
    padding-inline: 40px;
  }
} */
.card-buttom {
  background-color: var(--colorText);
  border: none;
  border-radius: 40px;
  /* color: var(--colorTitle); */
  color: #fff;
  padding: 10px;
  margin-top: -10px;
}

.card-buttom:hover {
  background-color: var(--colorTitle);
  border: none;
  border-radius: 40px;
  /* color: var(--colorText); */
  padding: 10px;
}

.cardImage {
  /* filter: drop-shadow(10px 8px 6px rgba(207, 207, 207, 0.7)); */
  overflow: hidden;
  color: #e6e6e6;
}

/* end of card style */

.MainCard {
  /* background:linear-gradient(#353453,#fff); */
}