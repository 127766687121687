.navbar {
    position: fixed;
    width: 100%;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 15px;
    background-color: #f8f9fa;
   
   
/* font-family: 'Comfortaa', cursive; */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Raleway', sans-serif; */
}

.navbar .logo {
    width: 150px;
    height: 60px;
    overflow: hidden;
}

.navbar .img-fluid {
    mask-size:cover;
    position: relative;
    bottom: 55px;
    width: 100%;
}



.navbar .nav-link{
    color : var(--colorTitle)
}
.navbar .join-btn {
    /* background-color: var(--color4);
    border-color: var(--color4); */
    /* background:linear-gradient(var(--bg1),var(--bg2)); */
    background: none;
    color: var(--colorText);
    border-color: #fff; 
    transition: all .3s ease;
}

.navbar .join-btn:hover {
    color: var(--primary);
    border-color: var(--primary); 
    
}

.navbar::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--primary);
    bottom: 0;
    left: 0;
}


    .account-logo{
        width: 55px;
        cursor:pointer;
       
        
    }
    .joinus-button{
        border: 1px solid var(--primary);
        border-radius: 5px;
        /* padding: 0; */
        width: fit-content;
        padding-inline: 5px;

    }
    .joinus-button:hover{
       
        border-radius: 10px;
        
    }


