/* basic styles */
.skeleton {
  background: #ddd;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
}
.skeleton.text {
  width: 100%;
  height: 12px;
}
.skeleton.title {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
}

.skeleton.small-box-sceleton {
  width: 100px;
  height: 30px;
  
}
.skeleton.small-button-sceleton {
  width: 90px;
  height: 25px;
 border-radius: 10px;
}
.skeleton.small-box-mobile{
  width: 30%;
  height: 25px;
 border-radius: 10px;

}
.skeleton.avatar {
  width: 90%;
  /* text-align: center; */
  align-self: center;
aspect-ratio: 1/1;
  border-radius: 50%;
}
.skeleton.avatarmobile {
  width: 100%;
  /* text-align: center; */
  align-self: center;
aspect-ratio: 1/1;
  border-radius: 50%;
}
.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}
.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* skeleton profile */


/* themes */
.skeleton-wrapper.light {
  background: #f2f2f2;
}
.skeleton-wrapper.dark {
  background: #444;
}
.skeleton-wrapper.dark .skeleton {
  background: #777;
}

/* animation effects */
.shimmer-wrapper {
  position: absolute;
  /* parent must be relative position & overflow hidden */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255,255,255,0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255,255,255,0.2);
}
.dark .shimmer {
  background: rgba(255,255,255,0.05);
  box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);
}

@keyframes loading {
  0% { transform: translateX(-150%) }
  50% { transform: translateX(-60%) }
  100% { transform: translateX(150%) }
}

/* categoryCart */
.parent-category{
  position: relative;
  overflow: hidden;
}
.parent-category.light {
  background: #f2f2f2;
}
.skeleton-category {
  max-width: 200px;
  width:45vw;
  aspect-ratio: 1/1;
}
/* product cart */
.parent-productweb{
  position: relative;
  overflow: hidden;
   background: #f2f2f2;
   width: 100%;
   height: 339px;
   border-radius: 5px;
   padding: 7px;
}
/* product cart slider*/
.parent-productslider{
  position: relative;
  overflow: hidden;
   background: #f2f2f2;
   width: 100%;
   height: 300px;
   border-radius: 5px;
   padding: 7px;
}
/* product product mobile*/
.parent-productCartMobile{
  position: relative;
  overflow: hidden;
   background: #f2f2f2;
   

  
   border-radius: 10px;
   margin-top: 10px;
   padding-inline: 5px;
 
   /*  */
   display: flex;
   flex-direction: row;
   padding-block: 5px;
   padding-inline: 0;

}

