.cartpage{
    background-image: url('../../assets/images/background1.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin-top: 75px;
    padding-top: 15px;
}
.cartpage .cart-image{
    width:90px;
}
.cartpage .text-title{
    font-size: 30px;
    color: var(--colorTitle);
    font-family: var(--fontText);
    padding-left: 5px;
    font-weight: bold;
}