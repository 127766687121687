.checkoutbody .locationIcon{
    width: 30px;
    padding-bottom: 7px;
}
.checkoutbody .titleSection{
    background-color: rgb(216, 235, 250);
    color:var(--colorTitle);
    font-family: var(--fontTitle);
    border-radius: 7px;
    padding: 5px;
    height: fit-content;
    font-size: 28px;

}

.checkoutbody .bodySection{
    background-color: rgb(233, 243, 252);
    font-family: var(--fontText);
    height: fit-content;
    padding-top: 20px;
    padding-inline: 10px;
    color: black;
}
.checkoutbody .inputOrder{
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    border: none;
}
.checkoutbody .labelOrder{
    margin-top: 5px;
}
