.transactionPage {

    padding-block: 100px;
    
}

.transaction .titleSectionTransaction{
    background-color: rgb(216, 235, 250);
    color:var(--colorTitle);
    font-family: var(--fontTitle);
    border-radius: 7px;
    padding: 5px;
    height: fit-content;
    font-size: 24px;

}

.transaction .bodySectionTransaction{
    background-color: rgb(233, 243, 252);
    font-family: var(--fontText);
    height: fit-content;
    /* padding-top: 20px;
    padding-inline: 10px; */
    color: black;
}

.transaction .status{
    font-size: 24px;
    font-weight: bold;
    color: rgb(255, 3, 3);
}

.transaction .admin-comment{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
}
.transaction .linkToWhatssap{
    color: rgb(0, 255, 0);
}
 .transaction .link-transaction{
    font-size: 14px;
    border-top:1px solid rgb(0, 255, 0) ;
 }
 .transaction .click-buttom{
    width: 20px;
 }
 .transaction .clickHear{
    cursor: pointer;
 }
 .trasaction .dateInTransaction{
    color:black;
    font-size: 12px;
   
    /* font-style: var(--fontTitle); */
 }