.SearchBar {
  text-align: center;

  /* max-width: 400px;; */
  /* margin-top: 50px; */
}

.SearchBar .search-container {
  position: relative;
  display: inline-block;
}

.SearchBar .search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.SearchBar .search-input {
  padding: 8px 40px 8px 30px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  width: 100%; 
 background-color: #d9e6f1;
  
}
.SearchBar .search-input::placeholder{
  color:  #8c9d9d;
  font-size: 14px;
}

.SearchBar .autocomplete-list {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
}

.SearchBar .autocomplete-item {
  padding: 8px;
  cursor: pointer;
}

.SearchBar img {
  width: 20px;
  height: 20px;
}