
.slider-background {
    /* background: linear-gradient(173.68deg, #ffedf5 0%, #ffd5e7 100%); */
    height: 250px;
    /* width: 294.26px; */
   
    /* margin-top: 52px; */
  }
  /* .slider-background2 {
    background: linear-gradient(174.76deg, #d3deff 0%, #a8baff 100%);
    height: 350px;
   
    margin-top: 52px;
  }
  .slider-background3 {
    background: linear-gradient(176.69deg, #f8f0d5 0%, #efdcab 100%);
    height: 350px;
   
    margin-top: 52px;
  }
  .slider-background4 {
    background: linear-gradient(175.24deg, #b2e8f0 0%, #7bccdc 100%);
    height: 350px;
  
    margin-top: 52px;
  } */
  .slider-title {
    /* color: #915970; */
    color:var(--colorTitle);
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
  }
  .slider-text {
    /* color: #915970; */
    color:var(--colorTitle);
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
  }
  .slider-image{}

  .carousel-indicators{
    margin-block: 0px;
  }