.card-store-mobile {

    border: 2px solid gray;
    border-radius: 10px;
    margin-top: 10px;
    padding-inline: 5px;
    background-color: white;



    .card-body {
        display: flex;
        flex-direction: row;
        padding-block: 5px;
        padding-inline: 0;
    }

    .card-image {
        flex: 2;
        border-right: 1px solid gray;
        padding-right: 5px;
    }

    .card-image img {
        width: 100%;
    }

    .card-details {
        flex: 5;
        width: 100%;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-name {
        /* border-bottom: 1px solid gray; */
        color: var(--colorTitle);
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 0px;
    }

    .card-description {
        /* overflow: hidden; */
        font-size: 14px;
        line-height: 16px;
        color:var(--colorText);
    }

    .card-pricing {
        display: flex;
        padding-bottom: 3px;
        flex-direction: row;
        justify-content: space-around;
    }

    .card-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .card-buttom {
        padding-block: 4px;
    }
}