.nav-text {}

.navbar{
    /* position: relative; */
    background-color: #f8f9fa;
}

.signIn-icon {
    width: 28px;
}


/* @media (min-width: 992px) {
    .filterBar {
        margin-top: 20px;
    }
} */

.filterBar {
    transition: all 0.5s ease;
}

@media (max-width: 992px) {
    .filterBar {
        margin-top: 75px;
    }
}
.count{
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: red;
    /* text-align: center; */
    width: 2ch; 
     height: 2ch;
     /* padding-bottom: 10px; */
    /* aspect-ratio: 1/1; */
    /* display: inline-block; */
    border-radius: 50%;
 

}
.item-navBar{
    position: relative;
}

.navbar-overlay
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
