.subCategoryList {

    .ScrollAreaRoot {
        width: 100%;
        height: fit-content;
        padding: 5px;
        /* border-radius: 4px; */
        overflow: hidden;
        /* box-shadow: 0 3px 3px var(--primary); */
        /* background-color: white; */
        /* background-color: rgba(233, 243, 252, 0.5); */
         background-color: #fff;
        --scrollbar-size: 10px;
    }

    .ScrollAreaViewport {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    .ScrollAreaScrollbar {
        display: flex;
        /* flex-direction: column; */
        /* ensures no selection */
        user-select: none;
        /* disable browser handling of all panning and zooming gestures on touch devices */
        touch-action: none;
        padding: 2px;
        background: none;
        transition: background 160ms ease-out;
    }

    /* .ScrollAreaScrollbar:hover {
        background: lightgray;
    } */

    .ScrollAreaScrollbar[data-orientation='vertical'] {
        display: none;
        width: var(--scrollbar-size);
    }

    .ScrollAreaScrollbar[data-orientation='horizontal'] {
        display: none;
        flex-direction: column;
        height: var(--scrollbar-size);
    }

    .ScrollAreaThumb {
        flex: 1;
        background: lightgray;
        border-radius: var(--scrollbar-size);
        position: relative;
    }

    /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
    .ScrollAreaThumb::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44px;
        min-height: 44px;
    }

    .ScrollAreaCorner {
        background: var(--primary);
    }

    .subCategoryCard {
        /* width: 180px; */
        width: fit-content;
        /* height: 70px; */
        /* aspect-ratio: 1/1; */
        background-color: rgb(216, 235, 250);
        border: 1px solid lightgray;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        /* padding: 5px; */
        margin-inline: 5px;
        border-radius: 10px;
        cursor: pointer;
        color: var(--colorTitle);

    }

    .subCategoryCard:hover {
        border: 1px solid rgb(139, 205, 255);
        background-color: var(--primary);
        color: white;
    }

    .selectedSubCategory {
        border: 1px solid rgb(139, 205, 255);
        background-color: var(--primary);
        color: white;
    }

    /* .subCategoryCard .subCategoryCartImage {
        height: 100%;
    } */

    .subCategoryCard .subCategoryCardName {
        font-size: 12px;
        font-weight: bolder;
        padding-inline: 10px;
        text-align: center;
        /* text-shadow: 0px 0px 4px white; */

    }
}