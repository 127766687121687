  /* discount setion */
  .discount-backcolor {
    height: 130px;
    border-radius: 8px;
    /* background: radial-gradient(circle, #767676 0%, #4d4f50 100%, #494c4d 100%); */
    background: radial-gradient(circle, #4efcff 0%, #d1eefd 100%, #4efcff 100%);
  }
  .discount-title {
    color: #ffffff;
    font-family: Cairo;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 39px;
  }
  .dicount-img {
    max-height: 130px;
    max-width: 80%;
  }
  /* end discount */