.categoryList {
    .ScrollAreaRoot {
        width: 100%;
        height: fit-content;
        padding: 10px;
        /* border-radius: 4px; */
        overflow: hidden;
        /* box-shadow: 0 2px 10px var(--primary); */
        background-color: white;
        /* background-color: rgb(233, 243, 252); */
        --scrollbar-size: 10px;
    }

    .ScrollAreaViewport {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    .ScrollAreaScrollbar {
        display: flex;
        /* flex-direction: column; */
        /* ensures no selection */
        user-select: none;
        /* disable browser handling of all panning and zooming gestures on touch devices */
        touch-action: none;
        padding: 2px;
        background: none;
        transition: background 160ms ease-out;
    }

    /* .ScrollAreaScrollbar:hover {
        background: lightgray;
    } */

    .ScrollAreaScrollbar[data-orientation='vertical'] {
        display: none;
        width: var(--scrollbar-size);
    }

    .ScrollAreaScrollbar[data-orientation='horizontal'] {
        /* display: none; */
        flex-direction: column;
        height: var(--scrollbar-size);
    }

    .ScrollAreaThumb {
        flex: 1;
        background: lightgray;
        border-radius: var(--scrollbar-size);
        position: relative;
    }

    /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
    .ScrollAreaThumb::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44px;
        min-height: 44px;
    }

    .ScrollAreaCorner {
        background: var(--primary);
    }

    .categoryCard {
        width: 180px;
        height: 70px;
        /* aspect-ratio: 1/1; */
        /* background-color: rgb(216, 235, 250); */
        border: 3px solid;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        /* padding: 5px; */
        margin-inline: 5px;
        border-radius: 10px;
        cursor: pointer;
        color: white;
        /* color: var(--colorTitle); */

    }

    .categoryCard:hover {
        border-color: yellowgreen;
        /* background-color: var(--primary);
        color: white; */

    }

    .selectedCategory {
        border-color: yellow;
        /* 
        border: 1px solid rgb(139, 205, 255);
        background-color: var(--primary);
        color: white; */
    }

    .categoryCard .categoryCartImage {
        height: 100%;
    }

    /* .categoryCard {
        width: 100%;
       
    } */

    .categoryCard .categoryCardName {
        font-size: 12px;
        font-weight: bolder;
        padding-inline: 10px 5px;
        /* text-align: center; */
        /* color: var(--colorTitle); */
        /* text-shadow: 0px 0px 4px white; */

    }

    .categoryelement {
        margin-inline: 20px;

        /* cursor: pointer; */
        width: max-content;

        /* border: 1px solid black; */
    }

    .categoryelementname {
        font-weight: bold;
        color: var(--colorTitle);
        cursor: pointer;
    }

    .categoryelementimage {
        width: 15px;
        margin-left: 5px;
        /* margin-bottom: 2px; */
        cursor: pointer;
    }

    /* list part */
    /* .dropdown .title-filter {
        font-family: var(--fontTitle);
        color: var(--colorTitle);
    }
     */
    .dropdown .card-filter1 {
        position: absolute;
        /* left: -150px; */
right: -50px;
        /* background-color: rgb(223, 242, 255); */
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        padding: 15px;
        width: max-content;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .dropdown .card-filter-item1 {
        font-size: 14px;
        font-weight: 400;
        padding:1px 5px;
        
    }

    .dropdown .card-filter-item1:hover {
        background: linear-gradient (#fff, rgb(235, 247, 255));
        color: var(--colorTitle);
        border-radius: 15px;
    }

    .dropdown .subCatWithSort {
        background-color: rgb(216, 235, 250);
    }

    .selectedSubCategory,
    .selectedCatergory {
        color: var(--primary);
    }


}