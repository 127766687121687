.userprofile {


  .button-profile {
    background-color: var(--colorText);
    border: none;
    border-radius: 40px;
    color: #fff;
  padding: 5px 15px;
    margin-top: -10px;
    width: fit-content;
    font-size: 18px;
  }

  .button-profile:hover {
    background-color: var(--colorTitle);
  
  }

  .header-profile{
    background-color: rgb(216, 235, 250);
    border-radius: 7px;
    padding: 5px;
    height: fit-content;
    color:var(--colorTitle);
    font-family: var(--fontTitle);
  }
  .body-profile{
    background-color: rgb(233, 243, 252);
    font-family: var(--fontText);
    height: fit-content;
    padding-top: 20px;
    padding-inline: 10px;
    color: black;
    font-size: 18px;
  }
  .input-profile{
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    border: none;
  }
  .email-logo{
    width: 20px;
  }
  .click-buttom{
    width: 20px;
  }
  .clickHear{
    cursor: pointer;
    color: var(--colorTitle);
  }

}