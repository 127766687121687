.footer-top {
    /* padding-top: 30px; */
    padding-bottom: 20px;
    /* background-color: #bfd7ed; */
    font-family: var(--fontTitle);
    background-color: rgb(216, 235, 250);
    /* background-color: rgb(233, 243, 252); */
}

.footer-top a {
    color: var(--body);
}

.footer-top a:hover {
    color: var(--white);
}
.social-icons {
    display: flex;
    justify-content: center;
}

.social-icons a {


    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 5px;
    transition: all 0.4s ease;

}

.footer-icon {
    width:100%;
}

.social-icons a:hover {
   
    scale: 1.1;
}

.sectionContainer {
    justify-content: center;
}

.footer-icon-location {
    width: 20px;
    height: auto;
    padding-bottom: 3px;
    margin-right: 5px;
}
.footer{
    border-top: 2px solid #8c9d9d ;
    
}

.footer .text-title{
    font-size: 30px;
    color: var(--colorTitle);
    font-family: var(--fontText);
    font-weight: bold;
}