.hero {
    /* background-image: url(../img/AI-E-commerce-01.jpg); */
    background-image: url(../../assets/images/home.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
/* margin-right: -5px; */
    margin-top: -1px;
    
}

.hero::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(21, 20, 51, 0.8);
    z-index: -1;
}
.hero .hero-title{
    font-family: var(--fontTitle);
}