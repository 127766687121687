.cartItem {
    background-color: white;
    border-radius: 15px;
    color: var(--colorTitle);
    border-width: 2px;
    border-style: solid;
    font-size: 16px;

}

.cartTitle {
    font-size: 22px;
    font-family: var(--fontTitle);
    line-height: 1;
}

.cartDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: inherit; */
}

.cartInfoKey {
    color: red;
    /* font-size: 16px; */
}

.cartColor {
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50%;
    margin-left: 10px;
}

.cartRemoveBtn {
    color: var(--colorTitle);
    border: none;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid transparent;
}

.cartRemoveBtn:hover {
    background-color: #eee;
    border: 2px solid var(--colorTitle);
}

.cartRemoveBtn>img {
    width: 25px;
}


.cartPriceOld {
    font-size: 14px;
}

.cartPriceNew {
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.cartPriceDiscount {
    font-size: 16px;
}

.cart-quantity{
    color:black;
}