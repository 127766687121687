
.form-contact input.form-control {
    height: 56px;
    font-family:var(--fontText) ;
    
}

.form-contact .form-control {
    border: transparent;
    border-radius: 0;
    /* background-color: rgba(0,0,0, 0.05); */
    background-color: rgb(233, 243, 252);
    
    font-family:var(--fontText) ;
    /* background-color:#35536a94 */
}
#contact p {
    color: black;
    font-family:var(--fontText) ;
    font-size: 18px;
    line-height: 1.3em;
    color: var(--colorText)
}

#contact h1 {
 
    font-family:var(--fontTitle) ;
    color: var(--colorTitle)
}

.contactButton{
    background-color: #c6c6c6;
    background-color: var(--colorText);
    color: #fff;
}
.contactButton:hover{
    background-color: #d4d4d4;
    background-color: var(--colorTitle);
    color: #fff;
}
.form-contact{
    /* background-color: rgb(233, 243, 252); */
}

