.StorePage2 {
    /* background-image: url('../../assets/images/background1.jpg'); */
    background-color: #f8f8f9;
    /* background-image: linear-gradient(120deg,rgb(240, 240, 240),rgb(230, 230, 230)); */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin-top: 75px;
}

.StorePage2 .no-result {
    padding-block: 50px;
    font-size: 48px;
    color: var(--body);
    font-family: var(--fontTitle);
}

.StorePage2 .title-filter {
    font-family: var(--fontTitle);
    color: var(--colorTitle);
}

.StorePage2 .card-filter {
    position: absolute;
    left: -170px;
    background-color: rgb(223, 242, 255);
    border-radius: 10px;
    padding: 15px;
    width: 180px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.StorePage2 .filterIcon {
    width: 20px;
    height: 20px;
    position: relative;
    padding-left: 3px;
    top: 7px;
}

.StorePage2 .subCatWithSort {
    /* background-color: rgb(216, 235, 250); */
    font-size: 18px;
}


.StorePage2 .sortBy {
    font-size: 12px;
    font-weight: bold;
    color: var(--colorTitle);
}