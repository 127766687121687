/* rates  */

.rate-container {
    height: 100%;
    width: 100%;
    padding-bottom: 12px;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
  }
  .rate-count {
    color: #979797;
    font-family: "Comfortaa";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
  }
  .rate-name {
    color: #555550;
    font-family: "Comfortaa";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  }
  .rate-description {
    color: #555550;
    font-family: "Comfortaa";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
  }

  .sub-tile {
    color: #272727;
    font-family: 'Comfortaa';
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
  }
  
.cat-rate {
    color: #ffc107;
    font-family: 'Comfortaa';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
  }

  .starimage{
width: 16px;
height: 16px;

  }

  .rate-totale{
    font-size: 40px;
    color: #fbff00;
  }
  /* end rate */