.productdescription .title-description{
    text-align: left;
    color:var(--lgi1);
    font-family: var(--fontTitle);
    font-size: 35px;
    border-bottom: 2px solid  var(--primary);

}

.productdescription .p-description{
    /* color:var(--colorText); */
    font-family: var(--fontText);
    font-size: 23px;
    margin-bottom: 50px;
}

.productdescription .p-description div >span:first-child{
    color:var(--colorTitle);
}