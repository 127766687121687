.CartItemMobile {
    color: var(--colorTitle);
    border: 2px solid var(--colorText);
    border-radius: 5px;
    font-size: 13px;
    padding-inline: 8px;
    padding-block: 5px;
    margin-bottom: 5px;
    background-color: white;

    .header {
        /* padding-bottom: 3px; */
        margin-bottom: 5px;
        height: 3ch;
        border-bottom: 1px solid var(--colorText);
        overflow: hidden;
    }

    .logo {
        width: 20px;
    }

    .title {
        font-family: var(--fontTitle);
        font-size: 18px;
        margin-top: -0.5ch;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .id_color {
        display: flex;
        align-items: center;
    }

    .cartColor{
        width: 2ch;
        height: 1ch;
        display: block;
        border-radius: 0;
    }

    .imageContainer {

        border-left: 1px solid var(--colorText);
    }

    .image {
        width: 100%;
    }

    .sizeMaterial{
        /* text-align: center; */
    }

    .body {}

    .RemoveBtn {
        color: var(--colorTitle);
        border: none;
        width: fit-content;
        padding: 5px;
        border-radius: 5px;
    font-size: 13px;
        border: 2px solid transparent;
    }

    .RemoveBtn:hover {
        background-color: #eee;
        border: 2px solid var(--colorTitle);
    }

    /* .cartPriceOld {
        font-size: 14px;
    }
    
    .cartPriceNew {
        font-size: 18px;
        color: black;
    }
    
    .cartPriceDiscount {
        font-size: 16px;
    } */
}