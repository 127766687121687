.card-effect {
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    padding: 25px;
    transition: all 0.35s ease;
}

.card-effect:hover {
    box-shadow: none;
    transform: translateY(5px);
}



#services .service-title0 {
    font-family: 'berlin sans fb';
    color: var(--colorTitle);
}
#services .service-title0 {
    font-family: 'berlin sans fb';
    color: var(--colorTitle);
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

#services{
    /* background-image: url(../../assets/images/background1.jpg); */
    background-color: #f8f8f9;
    background-position: center; 
    background-size: cover;
    padding-top: 40px;

}

.service::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -100%;
    left: 0;
    background-color: var(--primary);
    z-index: -1;
    opacity: 0;
    transition: all 0.4s ease;
}


/* .service:hover .iconbox {
    background-color: var(--white);
    color: var(--primary);
} */

/* .service:hover h5,
.service:hover p {
    color: var(--white);
    ;
} */

/* .service:hover::after {
    opacity: 1;
    top: 0;
} */


/* .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.serviceCard h4 {
  margin-block: 0;
    margin-left: 10px;
} */

.service .card-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
    padding: 10px;

}

.service .card-img:hover {
    /* width: 5px; */

}

.service .card-img img {
    width: 70px;
    object-fit: cover;
    height: 60px;
    /* width: 120px; */
    /* margin-left: -10px; */


    transform: rotateY(0deg);
    transition: transform 1s ease;
}

.service .card-img img:hover {
    transform: rotateY(180deg);

}

.service-descreption {
    /* text-align: start; */
    text-align: center;
    color: var(--body);
    margin-bottom: 0px;
}

.service-link {
    /* text-align: start; */
    display: block;
    text-align: center;
    margin: 0 auto;
    color: var(--colorTitle);
    margin-left: 0px;
    /* display: inline-block; */
    transition: transform 0.7s ease;
}

.service-link:hover {
   transform: translateX(10px);
   
}

.service-title {
    font-family: 'berlin sans fb';
    color: var(--colorTitle);
    /* text-align: start; */
    text-align: center;
    font-size: 22px;
    /* margin-top:-15px; */
}

.service-arrow {
    width: 30px;
    height: 30px;
}

.serviceCard {
    /* min-width: 180px; */
    max-width: 200px;
    width:45%;
    

}
.serviceCard .image-cart {
    width: 100%;
}
.CategoryRow{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    
}