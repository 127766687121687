.whatsapp-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 70px;
    height: auto;
    border-radius: 50%;
    /* background-color: green; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    scale:1;
    transition: scale 0.4s;
  }
  
  .whatsapp-button img {
    width: 100%;
    height: 100%;
  }

  .whatsapp-button:hover{
    /* width: 120px;
    height: auto; */
    scale: 1.1;
    
  }
