.sevicespage{
    background-image: url('../../assets/images/background1.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin-top: 75px;
    padding-top: 15px;
}
.imageServices{
    height: 180px;
    border-radius: 50%;
    /* padding: 7px; */
    background-color: aqua;
}
.cartServices{
    height: 200px;
    border-radius: 100px;
    width: 100%;
    background-color: rgb(233, 243, 252);
    padding: 10px;
}
.servicesTitle{
font-family: var(--fontTitle);
}

.contentServices{
    height: 180px;
    padding-left: 10px;
}