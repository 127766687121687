
    .favoritepage{
        background-image: url('../../assets/images/background1.jpg');
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        margin-top: 75px;
        padding-top: 15px;
    }
    .favoritepage .fav-header{
      
       display: flex;
       justify-content: center;
       align-items: center;
       /* margin-top: 130px; */
       
    }
    .favoritepage .fav-image img{
        width: 50px;
        
 }

        .favoritepage .fav-image img:hover{
            transform: rotateY(180deg);
        }
      
    .favoritepage .fav-title{
        color:var(--colorTitle);
        font-family: var(--fontTitle);
        margin-left: 10px;
        font-size: 35px;
    

   

    }

.fav-header2{
    text-align: center;
    color:var(--colorTitle);
}
.title-fav{
    font-family: var(--fontTitle);
    color:var(--colorTitle);
    padding-top: 20px;
}
.text-fav{
    font-family: var(--fontText);
    color:var(--colorText);
    font-size: 20px;
    padding-top: 25px;
}
.image-fav img{
    width: 80px;
    padding-top: 10px;
}
.button-fav{
    background-color: var(--colorText);
    border: none;
    border-radius: 40px;
    color: #fff;
    padding: 10px;
    
    margin-top: 20px;

  }
  .button-fav:hover{
    background-color: var(--colorTitle);
    border: none;
    border-radius: 40px;
    /* color: var(--colorText); */
    padding: 10px;
  }