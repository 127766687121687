.product-price {
    height: 46px;
    width: 150px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 9px;
    color: #555550;
    font-family: "Almarai";
    font-size: 12px;
    font-weight: bold;
    background-color: #ffffff;
  }

  .product-cart-add {
    cursor: pointer;
    text-align: center;
    height: 45px;
    border-radius: 9px;
    border: none;
    background-color: #272727;
    color: #ffffff;
    font-family: "Almarai";
    font-size: 14px;
    font-weight: normal;
  }
  .product-cart-add:hover {
    color: #bdbaba !important;
  }      

  .product-number:invalid{
    color: red;
    border-color:red;
  }
  .product-number{
    width: 100px;
    border: 1px solid var(--colorText);
    /* padding: -10px; */
    font-size: 18px;
    border-radius: 5px;
  }

  .productValue{
    color:var(--colorText);
    font-family: var(--fontText);
    font-size: 23px;
    
  }
  .productTitle{
    color:var(--colorTitle);
    font-family: var(--fontText);
    font-size: 23px;
    font-weight: bold;
    padding-right: 10px;
  }
  .productTitleMain{
    text-align: left;
    color:var(--lgi1);
    font-family: var(--fontTitle);
    font-size: 35px;
    line-height: 1;
  }

.add-btn {
    background-color: var(--colorText);
    /* border-color: var(--colorText); */
    /* background:linear-gradient(var(--bg1),var(--bg2)); */
    /* background: none; */
    color: #fff;
    border-color: var(--colorTitle); 
    transition: all .3s ease;
}

 .add-btn:hover {
    color: #fff;
    border-color: var(--colorText); 
    background-color: var(--colorTitle);
    
    /* border-radius: 10px 0px 10px 0px; 
    background:linear-gradient(var(--bg1),var(--bg2));
    background-color: transparent;
     border: 3px solid; */

}
.box-quantity-price {
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--colorTitle);
  /* border-radius: 10px; */

}