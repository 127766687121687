.bill{
    height: fit-content;
    /* width: 500px; */
    background-color: rgb(238, 248, 255);
    border:2px solid var(--colorText);
    border-radius: 10px;
    margin-bottom:50px ;
    font-size: 25px;
    
   
}
.bill .title-summary {
    text-align: center;
    margin-top: 10px;
    color: var(--colorTitle);
    font-family: var(--fontTitle);

}
.bill .count-summary {

    margin-top: 10px;
    font-family: var(--fontTitle);
    

}
.bill .key-bill {
    color: #000;
    font-family: var(--fontTitle);
}
.bill .value-bill {
    color: var(--colorTitle);
    font-family: var(--fontTitle);
}

.bill-button{
    background-color: var(--colorText);
    border: none;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
  
    font-size: 16px;
    margin-bottom: 20px;
  }
  .bill-button:hover{
    background-color: var(--colorTitle);
    border: none;
  
    color: #fff;
    padding: 10px;
  }
  .bill-totale-container{
    border-top: 1px solid var(--colorTitle);
    margin-top: 8px;
  }