@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kablammo&display=swap');

@font-face{
    font-family: 'berlin sans fb';
    src: url('./assets/font/BRLNSDB.TTF')
}

:root {
    /* --primary: #0d6dfd8f; */
    /* --primary: #5e9eff; */
    /* --primary: #8fbcff;  */
    --primary: #0074b7; 
    --dark: #21252f;
    --body: #888;
    --white: #ffffff;
    --box-shadow: 0 8px 22px rgba(0, 0, 0, 0.1);
    --color1: #CBE4DE;
    --color2: #0E8388;
    --color3: #ddb443;
    --color4: #0d6efd;
    --box-shadow: 0 8px 22px rgba(0, 0, 0, 0.171);

/* /// */
--bg1:#222c4f;
--bg2:#303251; 
--lgi1:#4a4873;
--lgi2:#4b4d4f;
--lgt1:#a40404;
--lgt2:#162b37;
--transparent:#ffffff00;

--fontTitle:'berlin sans fb';
--fontText:"Sora", sans-serif;
--colorTitle:#353453;
--colorText:#888;
--gold:#edf102;



}

body {
    font-family: "Sora", sans-serif;
    line-height: 1.7;
    color: var(--body);

}

h1,
h2,
h3,
h4,
h5,
h6,
.display-4 {
    color: var(--dark);
    font-weight: 700;
}

a {
    /* color: var(--dark); */
    text-decoration: none;
    cursor: pointer;
}

img {
    /* width: 100%; */
}

section {
    padding-top: 120px;
    padding-bottom: 120px;
}